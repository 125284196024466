var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "lg"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("div", [_c("b-alert", {
    attrs: {
      variant: "warning"
    },
    model: {
      value: _vm.alert.show,
      callback: function ($$v) {
        _vm.$set(_vm.alert, "show", $$v);
      },
      expression: "alert.show"
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_vm._v("Edit Addresses")]), _c("b-col", [_c("b-row", [_c("b-col", [_c("span", {
    staticClass: "text-small text-primary"
  }, [_vm._v("Shipping Address")]), _c("br"), _c("label", {
    staticClass: "text-small text-muted",
    attrs: {
      for: "shippingaddress1"
    }
  }, [_vm._v(" Street Address* ")]), _c("b-form-input", {
    attrs: {
      id: "shippingaddress1"
    },
    model: {
      value: _vm.shippingaddress.address1,
      callback: function ($$v) {
        _vm.$set(_vm.shippingaddress, "address1", $$v);
      },
      expression: "shippingaddress.address1"
    }
  })], 1)], 1), _c("b-row", {
    staticClass: "mt-2"
  }, [_c("b-col", [_c("label", {
    staticClass: "text-small text-muted",
    attrs: {
      for: "shippingaddress2"
    }
  }, [_vm._v(" Apartment/Suite ")]), _c("b-form-input", {
    attrs: {
      id: "shippingaddress2"
    },
    model: {
      value: _vm.shippingaddress.address2,
      callback: function ($$v) {
        _vm.$set(_vm.shippingaddress, "address2", $$v);
      },
      expression: "shippingaddress.address2"
    }
  })], 1)], 1), _c("b-row", {
    staticClass: "mt-2"
  }, [_c("b-col", [_c("label", {
    staticClass: "text-small text-muted",
    attrs: {
      for: "shippingcity"
    }
  }, [_vm._v(" City ")]), _c("b-form-input", {
    attrs: {
      id: "shippingcity"
    },
    model: {
      value: _vm.shippingaddress.city,
      callback: function ($$v) {
        _vm.$set(_vm.shippingaddress, "city", $$v);
      },
      expression: "shippingaddress.city"
    }
  })], 1), _c("b-col", [_c("label", {
    staticClass: "text-small text-muted",
    attrs: {
      for: "shippingstate"
    }
  }, [_vm._v(" State ")]), _c("b-form-input", {
    staticClass: "bg-light",
    attrs: {
      disabled: "",
      id: "shippingstate"
    },
    model: {
      value: _vm.shippingaddress.state,
      callback: function ($$v) {
        _vm.$set(_vm.shippingaddress, "state", $$v);
      },
      expression: "shippingaddress.state"
    }
  })], 1), _c("b-col", [_c("label", {
    staticClass: "text-small text-muted",
    attrs: {
      for: "shippingzipcode"
    }
  }, [_vm._v(" Zipcode ")]), _c("b-form-input", {
    attrs: {
      id: "shippingzipcode"
    },
    model: {
      value: _vm.shippingaddress.zipcode,
      callback: function ($$v) {
        _vm.$set(_vm.shippingaddress, "zipcode", $$v);
      },
      expression: "shippingaddress.zipcode"
    }
  })], 1)], 1), _c("b-row", {
    staticClass: "mt-3"
  }, [_c("b-col", [_c("span", {
    staticClass: "text-small text-primary"
  }, [_vm._v("Billing Address")]), _c("br"), _c("b-form-checkbox", {
    attrs: {
      value: true,
      "unchecked-value": false
    },
    model: {
      value: _vm.sameaddress,
      callback: function ($$v) {
        _vm.sameaddress = $$v;
      },
      expression: "sameaddress"
    }
  }, [_vm._v(" Same as shipping address ")])], 1)], 1), _c("b-row", {
    staticClass: "my-2"
  }, [_c("b-col", [_c("span", {
    staticClass: "text-small"
  }, [_c("b", [_vm._v("Note:")]), _vm._v(" If you wish to update the patient's shipping state, you may use the "), _c("i", [_vm._v("Transfer State")]), _vm._v(" function on the patient profile dropdown. ")])])], 1), _c("b-row", [!_vm.sameaddress ? _c("b-col", {
    staticClass: "mt-2"
  }, [_c("label", {
    staticClass: "text-small text-muted",
    attrs: {
      for: "billingaddress1"
    }
  }, [_vm._v(" Street Address* ")]), _c("b-form-input", {
    attrs: {
      id: "billingaddress1"
    },
    model: {
      value: _vm.billingaddress.address1,
      callback: function ($$v) {
        _vm.$set(_vm.billingaddress, "address1", $$v);
      },
      expression: "billingaddress.address1"
    }
  })], 1) : _vm._e()], 1), !_vm.sameaddress ? _c("b-row", {
    staticClass: "mt-2"
  }, [_c("b-col", [_c("label", {
    staticClass: "text-small text-muted",
    attrs: {
      for: "billingaddress2"
    }
  }, [_vm._v(" Apartment/Suite ")]), _c("b-form-input", {
    attrs: {
      id: "billingaddress2"
    },
    model: {
      value: _vm.billingaddress.address2,
      callback: function ($$v) {
        _vm.$set(_vm.billingaddress, "address2", $$v);
      },
      expression: "billingaddress.address2"
    }
  })], 1)], 1) : _vm._e(), !_vm.sameaddress ? _c("b-row", {
    staticClass: "mt-2"
  }, [_c("b-col", [_c("label", {
    staticClass: "text-small text-muted",
    attrs: {
      for: "billingcity"
    }
  }, [_vm._v(" City ")]), _c("b-form-input", {
    attrs: {
      id: "billingcity"
    },
    model: {
      value: _vm.billingaddress.city,
      callback: function ($$v) {
        _vm.$set(_vm.billingaddress, "city", $$v);
      },
      expression: "billingaddress.city"
    }
  })], 1), _c("b-col", [_c("label", {
    staticClass: "text-small text-muted",
    attrs: {
      for: "billingstate"
    }
  }, [_vm._v(" State ")]), _c("b-form-input", {
    attrs: {
      id: "billingstate"
    },
    model: {
      value: _vm.billingaddress.state,
      callback: function ($$v) {
        _vm.$set(_vm.billingaddress, "state", $$v);
      },
      expression: "billingaddress.state"
    }
  })], 1), _c("b-col", [_c("label", {
    staticClass: "text-small text-muted",
    attrs: {
      for: "billingzipcode"
    }
  }, [_vm._v(" Zipcode ")]), _c("b-form-input", {
    attrs: {
      id: "billingzipcode"
    },
    model: {
      value: _vm.billingaddress.zipcode,
      callback: function ($$v) {
        _vm.$set(_vm.billingaddress, "zipcode", $$v);
      },
      expression: "billingaddress.zipcode"
    }
  })], 1)], 1) : _vm._e(), _c("b-row", {
    staticClass: "mt-4",
    attrs: {
      "align-h": "end"
    }
  }, [_c("b-col", {
    attrs: {
      offset: "1"
    }
  }, [_c("b-button", {
    attrs: {
      pill: "",
      block: "",
      variant: "outline-dark",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Discard ")])], 1), _c("b-col", [_c("b-button", {
    attrs: {
      pill: "",
      block: "",
      variant: "secondary",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: _vm.submitChanges
    }
  }, [_vm._v(" Save ")])], 1)], 1)], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };