import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false,
      buttonDisabled: false,
      alert: {
        show: false,
        message: ''
      },
      shippingaddress: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: ''
      },
      sameaddress: true,
      billingaddress: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: ''
      }
    };
  },
  watch: {
    async showDialog(show) {
      if (show) {
        var _data$shippingaddress, _data$shippingaddress2, _data$shippingaddress3, _data$shippingaddress4, _data$shippingaddress5, _data$shippingaddress6, _data$billingaddress, _data$billingaddress2, _data$billingaddress3, _data$billingaddress4, _data$billingaddress5, _data$billingaddress6;
        const {
          data
        } = await PatientService.getPatient(this.rowData.id);
        this.shippingaddress.address1 = (_data$shippingaddress = data.shippingaddress) === null || _data$shippingaddress === void 0 ? void 0 : _data$shippingaddress.address1;
        this.shippingaddress.address2 = (_data$shippingaddress2 = data.shippingaddress) === null || _data$shippingaddress2 === void 0 ? void 0 : _data$shippingaddress2.address2;
        this.shippingaddress.city = (_data$shippingaddress3 = data.shippingaddress) === null || _data$shippingaddress3 === void 0 ? void 0 : _data$shippingaddress3.city;
        this.shippingaddress.state = (_data$shippingaddress4 = data.shippingaddress) === null || _data$shippingaddress4 === void 0 ? void 0 : _data$shippingaddress4.state;
        this.shippingaddress.zipcode = (_data$shippingaddress5 = data.shippingaddress) === null || _data$shippingaddress5 === void 0 ? void 0 : _data$shippingaddress5.zipcode;
        this.sameaddress = ((_data$shippingaddress6 = data.shippingaddress) === null || _data$shippingaddress6 === void 0 ? void 0 : _data$shippingaddress6.address1) == ((_data$billingaddress = data.billingaddress) === null || _data$billingaddress === void 0 ? void 0 : _data$billingaddress.address1);
        this.billingaddress.address1 = (_data$billingaddress2 = data.billingaddress) === null || _data$billingaddress2 === void 0 ? void 0 : _data$billingaddress2.address1;
        this.billingaddress.address2 = (_data$billingaddress3 = data.billingaddress) === null || _data$billingaddress3 === void 0 ? void 0 : _data$billingaddress3.address2;
        this.billingaddress.city = (_data$billingaddress4 = data.billingaddress) === null || _data$billingaddress4 === void 0 ? void 0 : _data$billingaddress4.city;
        this.billingaddress.state = (_data$billingaddress5 = data.billingaddress) === null || _data$billingaddress5 === void 0 ? void 0 : _data$billingaddress5.state;
        this.billingaddress.zipcode = (_data$billingaddress6 = data.billingaddress) === null || _data$billingaddress6 === void 0 ? void 0 : _data$billingaddress6.zipcode;
      } else {
        this.shippingaddress = {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipcode: ''
        };
        this.billingaddress = {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipcode: ''
        };
        this.sameaddress = true;
      }
    }
  },
  methods: {
    showAlert(message) {
      this.alert.message = message;
      this.alert.show = true;
    },
    async submitChanges() {
      this.alert.show = false;
      try {
        this.buttonDisabled = true;
        let shippingData = {
          address1: this.shippingaddress.address1,
          state: this.shippingaddress.state,
          zipcode: this.shippingaddress.zipcode.toString(),
          country: 'US',
          city: this.shippingaddress.city
        };
        if (this.shippingaddress.address2 != null) {
          if (this.shippingaddress.address2.trim().length > 0) {
            shippingData.address2 = this.shippingaddress.address2;
          }
        }
        await PatientService.updateShippingAddress(this.rowData.id, shippingData);
        if (this.sameaddress) {
          await PatientService.updateBillingAddress(this.rowData.id, {
            sameaddress: true
          });
        } else {
          let billingData = {
            address1: this.billingaddress.address1,
            zipcode: this.billingaddress.zipcode.toString(),
            country: 'US',
            state: this.billingaddress.state,
            city: this.billingaddress.city
          };
          if (this.billingaddress.address2 != null) {
            if (this.billingaddress.address2.trim().length > 0) {
              billingData.address2 = this.billingaddress.address2;
            }
          }
          await PatientService.updateBillingAddress(this.rowData.id, {
            sameaddress: false,
            billingaddress: billingData
          });
        }
        this.$emit('refresh-self');
        this.showDialog = false;
      } catch (err) {
        this.showAlert(err);
      } finally {
        this.buttonDisabled = false;
      }
    }
  }
};